@import './styles/theme.scss';

.App {
  display: flex;
  flex-direction: column;
  flex-wrap: unset;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: $color-background;
  color: $color-text;
}