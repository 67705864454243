@import '../../styles/fonts.scss';
@import '../../styles/theme.scss';

.docs-page-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  background-color: $color-markdown-background;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2em;
  background-color: $color-terciary-headline-bg;
  color: $color-markdown-header-text;
  font-size: 1.8em;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2)
}

.header-text {
  padding-right: 46px;
}

.content {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 12.0em);
  line-height: 2em;
  text-align: left;
  font-size: 0.8em;
}

.sections-container {
  width: 20%;
  overflow: auto;
  user-select: none;
  margin-top: 0.3em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;

  @media only screen and (max-width: $responsive-hide-step2) {
    &.rsp-hide-sections-container {
      display: none;
    }
  }
}