@import '../../styles/fonts.scss';
@import '../../styles/theme.scss';

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-height: 3.4em;
  margin-top: 1.0em;
  margin-bottom: 1.0em;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (max-width: $responsive-hide-step2) {
    &.rsp-header-container {
      margin-top: 0.8em;
      margin-bottom: 0.5em;
      justify-content: space-between;
    }
  }
}

.header-burguer {
  display: flex;
  width: 7.5%;
  min-width: 3.0em;
  height: 100%;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @media only screen and (max-width: $responsive-hide-step2) {
    &.rsp-click-burguer {
      pointer-events: all;
      cursor: pointer;
    }
  }
}

.header-burguer-icon {
  display: none;

  @media only screen and (max-width: $responsive-hide-step2) {
    &.rsp-show-burguer {
      display: block;
      z-index: 1;
    }
  }
}

.header-home {
  display: flex;
  height: 100%;
  justify-content: flex-end;

  @media only screen and (max-width: $responsive-hide-step2) {
    &.rsp-center-home {
      margin: auto;
      position: absolute;
      width: 100%;
      height: 2.5em;
      justify-content: center;
    }
  }
}

.header-home-K {
  display: flex;
  height: 100%;
  color: $color-home;
  cursor: pointer;
}

.header-buttons-bar {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 4%;
  flex-direction: row;
  gap: 1em;
  color: $color-header-buttons;

  @media only screen and (max-width: $responsive-hide-step2) {
    &.rsp-hide-buttons-bar {
      display: none;
    }
  }
}

.header-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.5s;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  padding-left: 2em;
  padding-right: 2em;
  border-radius: 10em;
  cursor: pointer;

  &[disabled] {
    color: gray;
    pointer-events: none;
  }
}

.header-button:hover {
  background-color: $over-background-color;
}

.header-button-text {
  display: flex;
  height: 100%;
  font-size: max(1em, 1.7vw);
}

.header-button-icon-containar {
  transition: 0.5s;
}

.header-button-icon {
  display: flex;
  height: max(1em, 2vw);
  margin-left: 45%;
}

.header-right-icons-container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 8em;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  margin-left: auto;

  @media only screen and (max-width: $responsive-hide-step2) {
    &.rsp-shrink-margin {
      margin-left: 0px;
      justify-content: flex-end;
      z-index: 1;
    }
  }
}

.header-github {
  cursor: pointer;
  pointer-events: all;

  & img {
    height: 1.8em;
  }
}

.header-x {
  margin-right: 35%;
  cursor: pointer;
  pointer-events: all;

  & img {
    height: 1.5em;
  }

  &[disabled] {
    color: gray;
    pointer-events: none;
  }

  @media only screen and (max-width: $responsive-hide-step2) {
    &.rsp-header-x-right {
      margin-left: 10%;
      margin-right: 10%;
    }
  }
}
