@import '../../styles/fonts.scss';
@import '../../styles/theme.scss';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
}

.shape {
  display: flex;
  transition: 0.5s ease;
  margin-bottom: 0;
  align-self: last baseline;
  clip-path: ellipse(150% 100% at 50% 150%);
  width: 100%;
  height: 100%;
  background-color: $color-primary;
}