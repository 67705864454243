@import './scrollbar.scss';

// White and grey doc theme
$color-background: rgb(24, 24, 38);
$color-background-highlight: rgb(40, 40, 40);
$color-logo: rgb(73, 69, 255);
$color-logo-subtitle: rgb(179, 177, 254);
$color-text: white;
$play-icon-bg: brightness(0) saturate(100%) invert(4%) sepia(26%) saturate(1324%) hue-rotate(201deg) brightness(90%) contrast(91%);;
$color-primary: rgb(20, 20, 31);
$color-primary-hover: rgb(37, 37, 37);
$color-secondary: rgb(89, 84, 84);
$color-secondary-get-started: rgb(33, 33, 52);
$color-secondary-hover-get-started: rgb(73, 69, 255);
$color-secondary-hover: rgb(192, 192, 207);
$color-mainbutton-text: rgb(192, 192, 207);
$color-terciary: rgb(255, 255, 255);
$color-terciary-headline-bg: rgb(33, 33, 52);
$color-home: rgb(73, 69, 255);
$color-header-buttons: rgb(192, 192, 207);
$color-markdown-background: rgb(24, 24, 38);
$color-markdown-header-text: rgb(192, 192, 207);
$color-markdown-code-border: rgb(192, 192, 207);
$color-markdown-quote-background: rgb(33, 33, 52);
$color-markdown-quote-text: rgb(163, 131, 191);
$color-markdown-code-background: #292929;
$color-markdown-secondary-inside-dropdown: rgb(192, 192, 207);
$color-markdown-secondary-inside-dropdown-selected: rgb(123, 121, 255);
$color-markdown-background-highlight-hover: rgb(33, 33, 52);
$color-markdown-secondary-selected: rgb(123, 121, 255);
$color-markdown-link: rgb(123, 121, 255);
$color-markdown-hover-link: rgb(73, 69, 255);
$color-markdown-hover-visited:rgb(141, 139, 253);
$color-markdown-visited:rgb(141, 139, 253);
$over-background-color:rgb(33, 33, 52);
$color-background-main-button: rgb(33, 33, 52);
$header-height: 6em;
$header-margin-top: 0.2em;
$footer-height: 5em;
$footer-margin-bottom: 3em;

$responsive-hide-step1: 1400px;
$responsive-hide-step2: 1024px;


// Black & White theme
// $color-background: rgb(65, 65, 65);
// $color-background-highlight: rgb(90, 90, 90);
// $color-logo: black;
// $color-text: #fafafa;
// $color-primary: rgb(30, 30, 30);
// $color-primary-hover: rgb(80, 80, 80);
// $color-secondary: black;
// $color-secondary-hover: rgb(60, 60, 60);
// $color-terciary: rgb(110, 110, 110);
// $color-home: black;
// $color-header-buttons: black;
// $color-mainbutton-text: black;
// $color-markdown-header-text: black;
// $color-markdown-code-border: rgb(240, 240, 240);
// $color-markdown-quote-text: rgb(170, 170, 170);
// $color-markdown-quote-background: rgb(20, 20, 20);
// $color-markdown-code-background: #003252;
// $header-height: 6em;
// $header-margin-top: 0.2em;
// $footer-height: 5em;
// $footer-margin-bottom: 3em;

// White theme
// $color-background: white;
// $color-background-highlight: rgb(230, 230, 230);
// $color-logo: black;
// $color-text: black;
// $color-primary: rgb(0, 66, 107);
// $color-primary-hover: rgb(0, 79, 129);
// $color-secondary: rgb(0, 98, 158);
// $color-secondary-hover: rgb(0, 125, 202);
// $color-terciary: rgb(0 71 116);
// $color-home: $color-primary;
// $color-header-buttons: black;
// $color-mainbutton-text: white;
// $color-markdown-header-text: white;
// $color-markdown-code-border: rgb(240, 240, 240);
// $color-markdown-quote-background: rgb(190, 190, 190);
// $color-markdown-quote-text: black;
// $color-markdown-code-background: #003252;
// $header-height: 6em;
// $header-margin-top: 0.2em;
// $footer-height: 5em;
// $footer-margin-bottom: 3em;